import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, P } from "../components/common/Typography";

function ProgramPage({ data }) {
  const programs = data.allContentfulProgram.nodes;

  return (
    <Layout>
      <SEO title="Πρόγραμμα" />

      <Section.Main>
        <H1>Πρόγραμμα</H1>
        <P>
          Στη σχολή μας λειτουργούν τμήματα για όλα τα είδη χορού που
          αναφέρονται στις ιστοσελίδες μας, για αρχάριους και προχωρημένους.
        </P>
        {programs.map(program => (
          <P key={program.contentful_id}>
            <a
              href={program.pdf.file.url}
              download={program.name}
              target="_blank"
              rel="noreferrer"
            >
              {program.name}
            </a>
          </P>
        ))}
      </Section.Main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllContentfulPrograms {
    allContentfulProgram(sort: { fields: createdAt, order: DESC }) {
      nodes {
        contentful_id
        name
        pdf {
          file {
            url
          }
        }
      }
    }
  }
`;

export default ProgramPage;
